export default class ItemHolder {
  selectable: boolean
  name?: string
  data?: CacheRecord
  task?: TaskInfo

  constructor(selectable: boolean, name?: string, data?: CacheRecord, task?: TaskInfo) {
    this.selectable = selectable
    this.name = name
    this.data = data
    this.task = task
  }

  copyWith({
    selectable,
    name,
    data,
    task
  }: {
    selectable?: boolean
    name?: string
    data?: CacheRecord
    task?: TaskInfo
  }): ItemHolder {
    return new ItemHolder(selectable ?? this.selectable, name ?? this.name, data ?? this.data, task ?? this.task)
  }
}
