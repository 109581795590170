<script setup lang="ts">
import type { PropType } from 'vue'
import { DownloadTaskStatus } from '~/enums/download_task_status'

interface Props {
  state: DownloadTaskStatus
  progress: number
  padding: string
}

const props: Props = defineProps({
  state: {
    type: Number as PropType<DownloadTaskStatus>,
    default: DownloadTaskStatus.undefined
  },
  progress: {
    type: Number as PropType<number>,
    default: 0.0
  },
  padding: {
    type: String as PropType<string>,
    default: '0'
  }
})
const emits = defineEmits(['click'])
function handleClick(e: any) {
  emits('click', e)
}

function buildBackgroundColor(status: DownloadTaskStatus): string {
  switch (status) {
    case DownloadTaskStatus.undefined:
    case DownloadTaskStatus.running:
    case DownloadTaskStatus.paused:
    case DownloadTaskStatus.failed:
    case DownloadTaskStatus.canceled:
    case DownloadTaskStatus.enqueued:
      return 'rgba(0, 0, 0, 0.5)'
    case DownloadTaskStatus.complete:
      return 'transparent'
    default:
      return 'transparent'
  }
}
</script>

  <template>
    <div
class="maskPro absolute left-0 top-0 h-4 w-full flex" :style="{
 padding,
      backgroundColor: buildBackgroundColor(state),
    }">
      <button
        class=""
        :style="{
          padding: '0',
          cursor: 'pointer'
        }"
        @click="handleClick"
      >
        <template v-if="state === DownloadTaskStatus.undefined">
          <span>未下载</span>
        </template>
        <template v-else-if="state === DownloadTaskStatus.running">
          <div>
            <span>下载中</span>
            <br />
            <span>{{ `${(progress * 100).toFixed(0)}%` }}</span>
            <br />
            <van-progress
              :percentage="progress"
              max="1"
              style="
                width: 100%;
                height: 3px;
                background-color: rgba(0, 0, 0, 0.38);
              "
            />
          </div>
        </template>
        <template v-else-if="state === DownloadTaskStatus.paused">
          <div>
            <span>暂停下载</span>
            <br />
            <img
              src="/icon_play_pause.png"
              alt="Play/Pause Icon"
              style="width: 14px; height: 22px;"
            />
          </div>
        </template>
        <template v-else-if="state === DownloadTaskStatus.failed">
          <span>下载失败</span>
        </template>
        <template v-else-if="state === DownloadTaskStatus.canceled">
          <span>下载超时,请稍后再试~</span>
        </template>
        <template v-else-if="state === DownloadTaskStatus.complete">
          <!-- Display nothing for 'complete' state -->
        </template>
        <template v-else-if="state === DownloadTaskStatus.enqueued">
          <span>准备下载</span>
        </template>
      </button>
    </div>
  </template>

  <style scoped>
  /* Add scoped CSS styles if needed */
  .maskPro{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 114px;
  }
  </style>
