// Enum definition
export enum DownloadTaskStatus {
  undefined,
  running,
  paused,
  failed,
  complete,
  canceled,
  enqueued
}
