<script setup lang="ts">
import { showToast } from 'vant'
import { PlayerApi } from '~/net/apis/player_api'
import ItemHolder from '~/net/models/ItemHolder'
import empty_download from '~/assets/image/empty/empty_download@2x.png'
import { CacheApi } from '~/net/apis/cache_api'
import { DownloadTaskStatus } from '~/enums/download_task_status'

// import { from, useObservable } from '@vueuse/rxjs'
const onClickLeft = () => history.back()
const checked = ref([])
const checked1 = ref(false)
const checkboxGroup = ref()
const checkboxRefs = ref<any[]>([])
const list = ref<ItemHolder[]>([])
const _tasks = ref<TaskInfo[]>([])
function checkAll() {
  console.log(checked1.value)
  if (checked1.value) {
    checkboxGroup.value.toggleAll(true)
  } else {
    checkboxGroup.value.toggleAll()
  }
}

const edit = ref(false)

async function getCacheRecord() {
  const { data } = await CacheApi.cacheRecord()
  console.log('data', data)
  // list.value = data
  // const _list =
  for (let i = 0; i < data.length; i++) {
    const _item = data[i]
    const _task = {
      name: _item.videoName,
      link: _item.videoUrl ?? '',
      filename: `${_item.videoId ?? '0'}.mp4`,
      progress: 0,
      status: DownloadTaskStatus.undefined
    }
    _tasks.value.push(_task)
    list.value.push(new ItemHolder(false, data[i].videoId, data[i], _task))
  }
}
getCacheRecord()

function choose(index: number) {
  checkboxRefs.value[index].toggle()
  console.log(checkboxRefs.value, checked.value)
  if (checked.value.length === list.value.length) {
    checked1.value = true
  } else {
    checked1.value = false
  }
}
const actions = [{ name: '确认删除' }]
const show = ref(false)
const type = ref('全部类型')
function onSelect(item: any) {
  show.value = false
  type.value = item.name
}

function onCheck(item: any) {
  console.log(item)
}
function handleActionClick(item?: ItemHolder, state?: string) {
  if (!item && !state)
    return
  startDownload(item)
}

async function startDownload(item?: ItemHolder) {
  if (!item)
    return
  const _task = item.task
  const _data = item.data
  if (!_task)
    return
  try {
    _task.progress = 0
    const _url = _data?.videoId
    const { data } = await PlayerApi.query({
      videoId: _data?.videoId
    })
    if (!data || !data.javVideoDTO || !data.javVideoDTO.videoUrl)
    {
      showToast('视频已下架')
      return
    }
    _task.progress = 0
    _task.status = DownloadTaskStatus.running
    await downloadFileWithProgress(data.javVideoDTO.videoUrl, (total: number, loaded: number) => {
      _task.progress = loaded / total
      _task.status = DownloadTaskStatus.running
    })
  } catch (error) {
    _task.progress = 0
    _task.status = DownloadTaskStatus.failed
    console.error('下载文件失败：', error)
  } finally {
    // downloading.value = false
  }
}

async function downloadFileWithProgress(url: string, onProgress: (total?: number, loaded?: number) => void) {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`)
  }

  const contentLength = response.headers.get('Content-Length')
  const total = contentLength ? Number.parseInt(contentLength, 10) : undefined
  let loaded = 0
  if (!response.body || !total)
    return
  const reader = response.body.getReader()
  while (true) {
    const { done, value } = await reader.read()
    if (done)
      break
    loaded += value.length
    onProgress(total, loaded)
  }

  return response.blob()
}
</script>

<template>
  <div h-screen ct-d0d0d0>
    <van-nav-bar title="我的下载" left-arrow @click-left="onClickLeft">
      <template #right>
        <button mr-2 ct-ffffff @click="edit = !edit">
          {{ edit ? '取消' : '编辑' }}
        </button>
      </template>
    </van-nav-bar>
    <div v-if="list.length > 0">
      <van-checkbox-group ref="checkboxGroup" v-model="checked" px-4>
        <van-row :gutter="[20, 20]">
          <van-col v-for="(item, index) in list" :key="index" span="12" @click="choose(index)">
            <div class="download-list-item relative">
              <MediaBaseCard :item="item?.data" :class="{ disabled: edit }" />
              <div v-if="edit" class="checkbox-wrapper">
                <van-checkbox
                  :ref="(el: any) => (checkboxRefs[index] = el)"
                  :name="item?.name"
                  icon-size="15"
                  checked-color="#FD8203"
                  @click.stop
                />
              </div>
              <MediaDownLoadProgress
                v-if="item && item.task && item.task.status !== DownloadTaskStatus.complete"
                :progress="(item.task.progress || 0) / 100"
                :state="item.task.status || DownloadTaskStatus.undefined"
                @click="(state) => handleActionClick(item, state)"
                @click.stop
              />
            </div>
          </van-col>
        </van-row>
      </van-checkbox-group>
      <div v-if="edit" fixed bottom-0 h-12.5 w-full flex items-center justify-between pl-2 pr-2 bg-242943 ct-e8e8e8>
        <van-checkbox v-model="checked1" checked-color="#FD8203" @click="checkAll"> 全选 </van-checkbox>
        <span @click="show = true">删除</span>
      </div>
    </div>
    <div v-else h-2xl flex flex-col items-center justify-center px-4>
      <img h-33 w-50 :src="empty_download" />
      <div mt-3 text-xs ct-ffffff>暂时没有下载影片</div>
    </div>
    <van-action-sheet
      v-model:show="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="show = false"
      @select="onSelect"
    />
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
.bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
:deep(.van-checkbox__label) {
  color: #e8e8e8;
}
:deep(.van-action-sheet__item) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__cancel) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__gap) {
  background: #414660;
}
:deep(.van-action-sheet__item:hover) {
  color: #fd5f03;
}
:deep(.van-action-sheet__content) {
  background: #414660;
}
.checkbox-wrapper {
  position: absolute;
  top: 90px;
  left: 6px;
}
.disabled {
  pointer-events: none;
}
</style>
